import { InfoCircleOutlined } from '@ant-design/icons';
import { Alert, Col, DatePicker, Divider, Form, Image, InputNumber, Modal, Radio, Row, Select, Spin, Statistic, Tooltip } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import DOMPurify from 'dompurify';
import moment from 'moment';
import { useEffect, useState } from 'react';
import api from '../../../api/api';
import { FALLBACK_IMAGE } from '../../../constants/types';
import { Button } from '../Button/Button';
import TableComponent from '../Table/TableComponent';
import './_custommodal.scss';

interface Props {
  isModalVisible: boolean;
  modalTitle: string;
  cancelHandler: () => void;
  confirmHandler?: () => void;
  type: string;
  data?: any;
  okModal?: boolean;
  buttonLoading?: boolean;
  primaryButtonHandler?: any;
  secondaryButtonHandler?: () => void;
}

const createMarkup = (html: any) => {
  return {
    __html: DOMPurify.sanitize(html),
  };
};

const Delete = (props: any) => {
  return (
    <>
      <div className="modal-title">
        <InfoCircleOutlined style={{ color: 'red' }} />
        &nbsp;&nbsp; Are you sure you want to delete {props.data.entries} {props.data.object}(s)?
      </div>
    </>
  );
};

const BulkDelete = (props: any) => {
  return (
    <>
      <div className="modal-title">
        <TableComponent
          columns={[
            {
              title: 'Employee ID',
              dataIndex: ['organisation', 'employee_id'],
              key: 'employee_id',
            },
            {
              title: 'Name',
              dataIndex: 'full_name',
              key: 'full_name',
            },
            {
              title: 'Date of Leaving',
              dataIndex: 'date_of_leaving',
              key: 'date_of_leaving',
              render: (text: any, data: any) => (
                <>
                  &nbsp;
                  <Form.Item
                    name={['date_of_leaving', `${data?.id}`]}
                    rules={[{ required: true, message: 'Please select date of leaving' }]}
                  >
                    <DatePicker
                      disabledDate={(current: any) => current.isAfter(moment())}
                      format="DD-MMM-YYYY"
                      suffixIcon
                      allowClear

                    />
                  </Form.Item>
                </>
              ),
            }
          ]}
          dataSource={props?.data}
          setRowKey="id"
          hasPaging={false}
        />
        <div className='mt-32' />
        <InfoCircleOutlined style={{ color: 'red' }} />
        &nbsp;&nbsp; Are you sure you want to delete the above listed members?
      </div>
    </>
  );
};

const GenericConfirmation = (props: any) => {
  return (
    <>
      <div className="modal-title">
        <InfoCircleOutlined style={{ color: 'red' }} />
        &nbsp;&nbsp; {props?.data?.message}
      </div>
    </>
  );
};

const GenericTable = (props: any) => {
  return (
    <>
      <div className="modal-title">
        {props?.data?.message}
        <div className='mt-32'>
          {props?.data?.table}
        </div>
      </div>
    </>
  );
};

const ApprovalModal = (props: any) => {
  return (
    <>
      <div className="modal-title">
        <InfoCircleOutlined style={{ color: 'green' }} />
        &nbsp;&nbsp; Are you sure you want to add {props.data.entries} member(s)?
      </div>
    </>
  );
};

const CancelModal = (props: any) => {
  return (
    <>
      <div className="modal-title">
        <InfoCircleOutlined style={{ color: 'red' }} />
        &nbsp;&nbsp; Are you sure you want to cancel adding the entry?
      </div>
    </>
  );
};

const ConfirmLeaving = (props: any) => {
  return (
    <>
      <div className="modal-title">
        <InfoCircleOutlined style={{ color: 'red' }} />
        &nbsp;&nbsp; Are you sure you want to leave the page? All your unsaved progress will be lost.
      </div>
    </>
  );
};

const AdminRole = (props: any) => {
  return (
    <>
      <div className="modal-title">
        <InfoCircleOutlined style={{ color: 'green' }} />
        &nbsp;&nbsp; Are you sure you want to {props?.data.type}{' '}
        <span className="text-green">{props?.data?.full_name}</span> as organisation admin?
      </div>
    </>
  );
};

const AddModal = (props: any) => {
  return (
    <>
      <div className="modal-title">
        <InfoCircleOutlined style={{ color: 'green' }} />
        &nbsp;&nbsp; Are you sure you want to update {props.data.entries || 'the'} {props?.data?.object || 'member(s)'}?
      </div>
      <Row justify="space-around">
        {props?.data?.totalEmployees >= 0 && <Col span={8}>
          <div className="modal-container">
            <div className="subtitle">Total Employees</div>
            <p className="modal-stats">{props?.data?.totalEmployees}</p>
          </div>
        </Col>}
        {props?.data?.entries >= 0 && <Col span={8}>
          <div className="modal-container">
            <div className="subtitle">Total Lives</div>
            <p className="modal-stats">{props?.data?.entries}</p>
          </div>
        </Col>}
      </Row>
    </>
  );
};

const BenefitsModal = (props: any) => {
  return (
    <>
      <Image preview={false} width={96} height={96} src={props?.data?.image} fallback={FALLBACK_IMAGE} />,
      <p className="margin-top">{props.data.benefit_description}</p>
    </>
  );
};

export const BulkMemberDeletionModal: React.FC<Props> = ({
  isModalVisible,
  confirmHandler,
  cancelHandler,
  modalTitle,
  data,
  buttonLoading,
}) => {
  const [form] = useForm(),
    deleteData = () => {
      form.validateFields().then((values) => {
        data.setDeleteData(data.dataSource.map((item: any, index: any) => {
          return {
            member_id: item?.uuid,
            date_of_leaving: form?.getFieldValue(['date_of_leaving', `${item?.id}`]) ? moment(form?.getFieldValue(['date_of_leaving', `${item?.id}`])).format('DD-MM-YYYY') : form?.getFieldValue(['date_of_leaving', `${item?.id}`]),
          };
        }
        ))
      });
    };

  useEffect(() => {
    if (data.deleteData?.[0]?.date_of_leaving) confirmHandler && confirmHandler();
  }, [data.deleteData]);

  return (
    <Modal title={modalTitle} visible={isModalVisible} footer={null} onCancel={cancelHandler}>
      <Form
        form={form}
      >
        <BulkDelete data={data.dataSource} />
      </Form>
      <Divider />
      <Row gutter={16} className="modal-footer">
        <Col>
          <Button type="secondary" onClick={cancelHandler}>
            Cancel
          </Button>
        </Col>
        <Col>
          <Button type="primary" loading={buttonLoading} onClick={deleteData}>
            Confirm
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

const CustomModal: React.FC<Props> = ({
  isModalVisible,
  confirmHandler,
  cancelHandler,
  type,
  modalTitle,
  data,
  okModal = false,
  buttonLoading,
}) => {
  return (
    <Modal title={modalTitle} visible={isModalVisible} footer={null} onCancel={cancelHandler}>
      {type === 'deletion' && <Delete data={data} />}
      {type === 'bulk-deletion' && <BulkDelete data={data} />}
      {type === 'addition' && <AddModal data={data} />}
      {type === 'generic-confirmaion' && <GenericConfirmation data={data} />}
      {type === 'generic-table' && <GenericTable data={data} />}
      {type === 'approval' && <ApprovalModal data={data} />}
      {type === 'benefits' && <BenefitsModal data={data} />}
      {type === 'cancel' && <CancelModal data={data} />}
      {type === 'adminrole' && <AdminRole data={data} />}
      {type === 'confirmleaving' && <ConfirmLeaving data={data} />}
      <Divider />

      {okModal ? (
        <Row gutter={16} className="modal-footer" justify='end'>
          <Button type="primary" onClick={confirmHandler}>
            Close
          </Button>
        </Row>
      ) : (
        <Row gutter={16} className="modal-footer">
          <Col>
            <Button type="secondary" onClick={cancelHandler}>
              Cancel
            </Button>
          </Col>
          <Col>
            <Button type="primary" loading={buttonLoading} onClick={confirmHandler}>
              Confirm
            </Button>
          </Col>
        </Row>
      )}
    </Modal>
  );
};

export const EndorsementSelectionModal: React.FC<Props> = ({
  isModalVisible,
  primaryButtonHandler,
  secondaryButtonHandler,
  cancelHandler,
  modalTitle,
  data,
  buttonLoading,
}) => {
  const [value, setValue] = useState('SELECT' as any),
    onChange = (e: any) => {
      setValue(e.target.value);
    },
    submitHandler = () => {
      if (value === 'SELECT') {
        primaryButtonHandler && primaryButtonHandler();
      } else {
        secondaryButtonHandler && secondaryButtonHandler();
      }
    };


  return (
    <Modal title={modalTitle} visible={isModalVisible} footer={null} onCancel={cancelHandler} destroyOnClose>
      <GenericTable data={data} />
      <Divider />
      <div className="mt-24" />
      <Radio.Group onChange={onChange} value={value}>
        <Radio value={'SELECT'}>Select dependents of the selected members and proceed</Radio>
        <Radio value={'DESELECT'}>Deselect self with unselected dependents and proceed</Radio>
      </Radio.Group>
      <div className="mt-24" />
      <Row gutter={16} className="modal-footer">
        <Col>
          <Button type="secondary" onClick={cancelHandler}>
            Cancel
          </Button>
        </Col>
        <Col>
          <Button type="primary" loading={buttonLoading} onClick={submitHandler}>
            Proceed
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

export const UserDeletionModal: React.FC<Props> = ({
  isModalVisible,
  primaryButtonHandler,
  secondaryButtonHandler,
  cancelHandler,
  modalTitle,
  data,
  type,
}) => {
  const [listOfClaimsManagers, setListOfClaimsManagers] = useState([]),
    [listOfPolicyManagers, setListOfPolicyManagers] = useState([]),
    [userData, setUserData] = useState({} as any),
    [listOfCSA, setListOfCSA] = useState([]),
    submitHandler = () => {
      form.validateFields().then((values) => {
        // console.log(values);
        // console.log(data?.id);
        // return;
        setButtonLoading(true);
        api.user_management.moveUserClients({ user_id: data?.id, relationship_manager_data: values })
          .then((response) => {
            primaryButtonHandler && primaryButtonHandler();
            setButtonLoading(false);
          })
          .catch((error) => {
            setButtonLoading(false);
          })
      })
    },
    [loader, setLoader] = useState(true),
    [rmLoader, setRmLoader] = useState({
      claim_manager: false,
      policy_manager: false,
      customer_success_associate: false,
    }),
    [buttonLoading, setButtonLoading] = useState(false),
    { Option } = Select,
    [form] = useForm(),
    fetchRelationshipManagers = async (value: string, setFunction: any) => {
      setRmLoader((prevState: any) => ({ ...prevState, [value]: true }));
      api.clientaddition
        .userList(value)
        .then((response) => {
          setFunction(response.data?.filter((user: any) => user?.user !== data?.id));
          setRmLoader((prevState: any) => ({ ...prevState, [value]: false }));
        })
        .catch((error) => {
          setRmLoader((prevState: any) => ({ ...prevState, [value]: false }));
        });
    },
    fetchUserDetails = () => {
      api.user_management.checkUserRoles(data?.id)
        .then((response) => {
          setUserData(response);
          setLoader(false);
          if (response?.client_count > 0) {
            fetchRelationshipManagers('claim_manager', setListOfClaimsManagers);
            fetchRelationshipManagers('policy_manager', setListOfPolicyManagers);
            fetchRelationshipManagers('customer_success_associate', setListOfCSA);
          }
        })
        .catch((error) => {
          setLoader(false);
        })
    };

  useEffect(() => {
    if (isModalVisible && type === 'user') fetchUserDetails();
    else if (isModalVisible && type === 'role') {
      setLoader(false);
      if (data?.additionalData?.find((item: any) => item?.name === 'policy_manager' && item?.client_count > 0)) fetchRelationshipManagers('policy_manager', setListOfPolicyManagers);
      if (data?.additionalData?.find((item: any) => item?.name === 'claim_manager' && item?.client_count > 0)) fetchRelationshipManagers('claim_manager', setListOfClaimsManagers);
      if (data?.additionalData?.find((item: any) => item?.name === 'customer_success_associate' && item?.client_count > 0)) fetchRelationshipManagers('customer_success_associate', setListOfCSA);
    }
    else if (!isModalVisible) {
      setListOfCSA([]);
      setListOfClaimsManagers([]);
      setListOfPolicyManagers([]);
      setUserData({} as any);
      setButtonLoading(false);
      form.resetFields();
    }
  }, [isModalVisible]);

  return (
    <Modal title={modalTitle} visible={isModalVisible} footer={null} onCancel={cancelHandler} destroyOnClose>
      <Spin spinning={loader}>
        {(userData?.client_count === 0 || !userData?.client_count && type === 'user') && <>Are you sure you want to delete <span style={{ fontWeight: 600 }}>{data?.first_name}</span> from the system?</>}
        {!loader && (userData?.client_count > 0 || data?.additionalData?.filter((item: any) => item?.client_count > 0)) &&
          <>
            There {userData?.client_count === 1 ? 'is' : 'are'} <span style={{ fontWeight: 600 }}>{userData?.client_count || 'some'}</span> client{userData?.client_count === 1 ? '' : 's'} assigned to <span style={{ fontWeight: 600 }}>{data?.first_name}</span>, please select new users to assign to these clients
            <Divider />
            <div className="mt-24" />
            {type === 'user' &&
              <Form form={form} name="rm-details" scrollToFirstError={true}>
                {data?.roles?.find((item: any) => item?.name === 'claim_manager') &&
                  <Form.Item
                    label={`Claims Manager`}
                    labelCol={{ span: 24 }}
                    name="claim_manager_id"
                    validateTrigger="onBlur"
                    rules={[{ required: true, message: "Please select the assigned Claims Manager!" }]}
                  >
                    <Select
                      loading={rmLoader?.claim_manager}
                      showSearch
                      placeholder="Select a Claim Manager"
                      filterOption={(inputValue: any, option: any) =>
                        option.props.children.toString().toLowerCase().includes(inputValue.toLowerCase())
                      }
                    >
                      {listOfClaimsManagers.map((data: any) => {
                        return (
                          <Option key={data.id} value={data.id}>
                            {data.full_name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>}

                {data?.roles?.find((item: any) => item?.name === 'policy_manager') &&
                  <Form.Item
                    label={`Policy Manager`}
                    labelCol={{ span: 24 }}
                    name="policy_manager_id"
                    validateTrigger="onBlur"
                    rules={[{ required: true, message: "Please select the assigned Policy Manager!" }]}
                  >
                    <Select
                      loading={rmLoader?.policy_manager}
                      showSearch
                      placeholder="Select a Policy Manager"
                      filterOption={(inputValue: any, option: any) =>
                        option.props.children.toString().toLowerCase().includes(inputValue.toLowerCase())
                      }
                    >
                      {listOfPolicyManagers.map((data: any) => {
                        return (
                          <Option key={data.id} value={data.id}>
                            {data.full_name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>}

                {data?.roles?.find((item: any) => item?.name === 'customer_success_associate') &&
                  <Form.Item
                    label={`Customer Success Associate`}
                    labelCol={{ span: 24 }}
                    name="customer_success_associate_id"
                    validateTrigger="onBlur"
                    rules={[{ required: true, message: "Please select the assigned CSA!" }]}
                  >
                    <Select
                      loading={rmLoader?.customer_success_associate}
                      showSearch
                      placeholder="Select a CSA"
                      filterOption={(inputValue: any, option: any) =>
                        option.props.children.toString().toLowerCase().includes(inputValue.toLowerCase())
                      }
                    >
                      {listOfCSA?.map((data: any) => {
                        return (
                          <Option key={data.id} value={data.id}>
                            {data.full_name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>}
              </Form>}

            {type === 'role' &&
              <Form form={form} name="rm-details" scrollToFirstError={true}>
                {data?.additionalData?.find((item: any) => item?.name === 'claim_manager' && item?.client_count > 0) &&
                  <Form.Item
                    label={`Claims Manager (${data?.additionalData?.find((item: any) => item?.name === 'claim_manager')?.client_count})`}
                    labelCol={{ span: 24 }}
                    name="claim_manager_id"
                    validateTrigger="onBlur"
                    rules={[{ required: true, message: "Please select the assigned Claims Manager!" }]}
                  >
                    <Select
                      loading={rmLoader?.claim_manager}
                      showSearch
                      placeholder="Select a Claim Manager"
                      filterOption={(inputValue: any, option: any) =>
                        option.props.children.toString().toLowerCase().includes(inputValue.toLowerCase())
                      }
                    >
                      {listOfClaimsManagers.map((data: any) => {
                        return (
                          <Option key={data.id} value={data.id}>
                            {data.full_name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>}

                {data?.additionalData?.find((item: any) => item?.name === 'policy_manager' && item?.client_count > 0) &&
                  <Form.Item
                    label={`Policy Manager (${data?.additionalData?.find((item: any) => item?.name === 'policy_manager')?.client_count})`}
                    labelCol={{ span: 24 }}
                    name="policy_manager_id"
                    validateTrigger="onBlur"
                    rules={[{ required: true, message: "Please select the assigned Policy Manager!" }]}
                  >
                    <Select
                      loading={rmLoader?.policy_manager}
                      showSearch
                      placeholder="Select a Policy Manager"
                      filterOption={(inputValue: any, option: any) =>
                        option.props.children.toString().toLowerCase().includes(inputValue.toLowerCase())
                      }
                    >
                      {listOfPolicyManagers.map((data: any) => {
                        return (
                          <Option key={data.id} value={data.id}>
                            {data.full_name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>}

                {data?.additionalData?.find((item: any) => item?.name === 'customer_success_associate' && item?.client_count > 0) &&
                  <Form.Item
                    // label="Customer Success Associate"
                    label={`Customer Success Associate (${data?.additionalData?.find((item: any) => item?.name === 'customer_success_associate')?.client_count})`}
                    labelCol={{ span: 24 }}
                    name="customer_success_associate_id"
                    validateTrigger="onBlur"
                    rules={[{ required: true, message: "Please select the assigned CSA!" }]}
                  >
                    <Select
                      loading={rmLoader?.customer_success_associate}
                      showSearch
                      placeholder="Select a CSA"
                      filterOption={(inputValue: any, option: any) =>
                        option.props.children.toString().toLowerCase().includes(inputValue.toLowerCase())
                      }
                    >
                      {listOfCSA?.map((data: any) => {
                        return (
                          <Option key={data.id} value={data.id}>
                            {data.full_name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>}
              </Form>}
          </>}
        <div className="mt-24" />
        <Row gutter={16} className="modal-footer">
          <Col>
            <Button type="secondary" onClick={cancelHandler}>
              Cancel
            </Button>
          </Col>
          <Col>
            <Button type="primary" loading={buttonLoading} onClick={submitHandler}>
              Proceed
            </Button>
          </Col>
        </Row>

      </Spin>
    </Modal>
  );
};

export const MoveBatchModal: React.FC<Props> = ({
  isModalVisible,
  primaryButtonHandler,
  cancelHandler,
  modalTitle,
  data,
  buttonLoading,
}) => {
  return (
    <Modal title={modalTitle} visible={isModalVisible} footer={null} onCancel={cancelHandler} destroyOnClose>
      <GenericTable data={data} />
      <Divider />
      <div className="mt-24" />
      <Row gutter={16} className="modal-footer">
        <Col>
          <Button type="secondary" onClick={cancelHandler}>
            Cancel
          </Button>
        </Col>
        <Col>
          <Button type="primary" loading={buttonLoading} onClick={primaryButtonHandler}>
            Move
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

export const EndorsementPremiumConfirmationModal: React.FC<Props> = ({
  isModalVisible,
  confirmHandler,
  cancelHandler,
  modalTitle,
  data,
  buttonLoading,
  type,
}) => {
  const [btnDisabled, setBtnDisabled] = useState(true);
  return (
    <Modal title={modalTitle} visible={isModalVisible} footer={null} onCancel={cancelHandler} destroyOnClose>
      <Row justify="space-around" gutter={[16, 32]}>
        <Col span={12}>
          <div className="modal-container no-margin">
            <div className="subtitle">Total Lives</div>
            <p className="modal-stats">{data?.count}</p>
          </div>
        </Col>
        <Col span={12}>
          <div className="modal-container no-margin">
            <div className="subtitle">Total {type === 'MODIFICATION' && 'Updated '}{data?.premium !== null && 'Premium'}{data?.refund !== null && 'Refund'}</div>
            <p className="modal-stats">₹
              &nbsp;
              {data?.premium !== null && Math.round(data?.premium)?.toLocaleString('en-IN')}
              {data?.refund !== null && Math.round(data?.refund)?.toLocaleString('en-IN')}
              {data?.premium === null && data?.refund === null && '0'}
            </p>
          </div>
        </Col>
        {type === 'MODIFICATION' && <>
          <Col span={12}>
            <div className="modal-container no-margin">
              <div className="subtitle">Old Premium</div>
              <p className="modal-stats">₹
                &nbsp;
                {data?.old_premium !== null && Math.round(data?.old_premium)?.toLocaleString('en-IN')}
                {data?.old_premium === null && '0'}
              </p>
            </div>
          </Col>
          <Col span={12}>
            <div className="modal-container no-margin">
              <div className="subtitle">Change in Premium</div>
              <p className="modal-stats">₹
                &nbsp;
                {data?.old_premium !== null && data?.premium !== null && Math.round(data?.premium - data?.old_premium)?.toLocaleString('en-IN')}
                {data?.old_premium === null && data?.premium === null && '0'}
              </p>
            </div>
          </Col>
        </>}
      </Row>
      <Divider />
      <div className="mt-24" />
      {data?.premium !== null && type !== 'MODIFICATION' && <Alert
        message={data?.premium <= data?.cd_balance ? `This action affects client's CD balance. Please confirm the following endorsement request by entering the total premium amount mentioned above` : `Client does not have enough CD balance to process these endorsements.`}
        type={data?.premium <= data?.cd_balance ? 'warning' : 'error'}
        closable={false}
      />}
      {data?.premium !== null && data?.old_premium !== null && type === 'MODIFICATION' && <Alert
        message={((data?.premium - data?.old_premium) <= data?.cd_balance || (data?.premium - data?.old_premium)===0) ? `This action affects client's CD balance. Please confirm the following endorsement request by entering the total updated premium amount mentioned above` : `Client does not have enough CD balance to process these endorsements modifications.`}
        type={(data?.premium - data?.old_premium) <= data?.cd_balance ? 'warning' : 'error'}
        closable={false}
      />}
      {/* <div className=''>{data?.message}</div> */}
      <div className="mt-24" />
      {type !== 'MODIFICATION' && data?.premium !== null && data?.premium <= data?.cd_balance && <InputNumber
        min={0}
        size="small"
        addonBefore="₹"
        formatter={(value) => (value ? Math.round(parseFloat(value))?.toLocaleString('en-IN') : '')}
        className="w-addon"
        placeholder='Confirm total premium amount mentioned above'
        onChange={(e) => {
          // console.log(e.toFixed(3) + '', data?.premium?.toFixed(3));
          if ((e === Math.round(data?.premium)) && data?.premium <= data?.cd_balance) {
            setBtnDisabled(false);
          }
          else {
            setBtnDisabled(true);
          };
        }}
      />}
      {type !== 'MODIFICATION' && data?.refund !== null && <InputNumber
        min={0}
        size="small"
        addonBefore="₹"
        formatter={(value) => (value ? Math.round(parseFloat(value))?.toLocaleString('en-IN') : '')}
        className="w-addon"
        placeholder='Confirm total refund amount mentioned above'
        onChange={(e) => {
          // console.log(e.toFixed(3) + '', data?.refund?.toFixed(3))
          if (e === Math.round(data?.refund)) {
            setBtnDisabled(false);
          }
          else {
            setBtnDisabled(true);
          };
        }}
      />}
      {type === 'MODIFICATION' && data?.old_premium !== null && ((data?.premium - data?.old_premium) <= data?.cd_balance || (data?.premium - data?.old_premium) ===0) && <InputNumber
        min={0}
        size="small"
        addonBefore="₹"
        formatter={(value) => (value ? Math.round(parseFloat(value))?.toLocaleString('en-IN') : '')}
        className="w-addon"
        placeholder='Confirm total updated premium amount mentioned above'
        onChange={(e) => {
          // console.log(e.toFixed(3) + '', data?.refund?.toFixed(3))
          if (e === Math.round(data?.premium)) {
            setBtnDisabled(false);
          }
          else {
            setBtnDisabled(true);
          };
        }}
      />}
      <Divider />
      <Row gutter={16} className="modal-footer">
        <Col>
          <Button type="secondary" onClick={cancelHandler}>
            Cancel
          </Button>
        </Col>
        <Col>
          <Button type="primary" loading={buttonLoading} onClick={confirmHandler} disabled={btnDisabled}>
            Confirm
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

export const PricingLogicModal: React.FC<Props> = ({
  isModalVisible,
  confirmHandler,
  cancelHandler,
  modalTitle,
  data,
  // loading,
}) => {
  const [btnDisabled, setBtnDisabled] = useState(true);
  return (
    <Modal title={modalTitle} visible={isModalVisible} footer={null} onCancel={cancelHandler} destroyOnClose>
      <Row gutter={16} className="margin-top">
        <Col span={8}>
          <Statistic
            // loading={loading}
            title={
              <span>
                Inception Payment Frequency
                <Tooltip title="CD Balance is the amount of money that is available in the client's account.">
                  <InfoCircleOutlined className="ml-8" />
                </Tooltip>
              </span>
            }
            value={data?.cd_balance || '-'} />
        </Col>
        <Col span={8}>
          <Statistic
            // loading={loading}
            title={
              <span>
                Endorsement Payment Frequency
                <Tooltip title="Net Premium collected for the product">
                  <InfoCircleOutlined className="ml-8" />
                </Tooltip>
              </span>
            } value={data?.net_premium || '-'}
          />
        </Col>
        <Col span={8}>
          <Statistic
            // loading={loading}
            title={
              <span>
                Estimated Next Payment Date
                <Tooltip title="Total Commission Earned on the net premium for the product.">
                  <InfoCircleOutlined className="ml-8" />
                </Tooltip>
              </span>
            }
            value={data?.commission_earned || '-'}
          />
        </Col>
      </Row>
      <Divider />
      <div className="mt-24" />
    </Modal>
  );
};

export const NoteModal: React.FC<Props> = ({
  isModalVisible,
  confirmHandler,
  cancelHandler,
  modalTitle,
  data,
}) => {
  return (
    <Modal title={modalTitle} visible={isModalVisible} footer={null} onCancel={cancelHandler} destroyOnClose width={800}>
      <Row gutter={16} className="">
        <div
          className="description"
          dangerouslySetInnerHTML={createMarkup(data?.notes)}
        />
      </Row>
    </Modal>
  );
};

export default CustomModal;
