import { Alert, Col, Form, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../../../../../api/api";
import Loader from "../../../../../../components/Loader/Loader";
import { Button } from "../../../../../../components/UI/Button/Button";
import { NoteModal } from "../../../../../../components/UI/Modals/CustomModal";
import { successNotification } from "../../../../../../components/UI/Toast/Toast";
import Breadcrumbs, { ActiveBreadcrump } from "../../../../../../components/header/Breadcrumbs";
import { errorHandler } from "../../../../../../utils/helper";
import AddPolicyTransactions from "./AddPolicyTransactions";
import moment from "moment";
import { useSelector } from "react-redux";
import { UNDEFINED_ARRAY } from "../../../../../../constants/config";


interface Props {
  transaction_only?: boolean;
}

const AddTransactionDetails: React.FC<Props> = ({ transaction_only }) => {
  const [data, setData] = useState<any | null>(null),
    [initialData, setInitialData] = useState<any | null>(null),
    [loading, setLoading] = useState(false),
    [form] = Form.useForm(),
    [clientDetails, setClientDetails] = useState({} as any),
    [btnloading, setBtnloading] = useState(false),
    [btnDisabled, setBtnDisabled] = useState(true),
    [newProduct, setNewProduct] = useState<undefined | any>(undefined),
    listOfProducts = useSelector((state: any) => state.masterdata.listOfProducts),
    [helpModalVisible, setHelpModalVisible] = useState(false),
    { id } = useParams(),
    navigate = useNavigate(),
    navigateToConfiguration = () => {
      navigate(-1)
    },
    reformatPayload = (values: any) => {
      return values?.products?.filter((item: any) => item?.type === 'policy' && item?.valid)?.map((item: any) => {
        return {
          product_id: item?.newProduct ? item?.id : item?.provider_product_mapping?.product?.id,
          category: item?.newProduct ? item?.category : item?.provider_product_mapping?.product?.category,
          provider_id: item?.newProduct ? item?.provider_name : item?.provider_product_mapping?.provider?.id,
          ...item?.lives_covered && { lives_covered: item?.lives_covered },
          policy: {
            ...item?.policy_number && { policy_number: item?.policy_number },
            ...item?.internal_remarks && { remarks: item?.internal_remarks },
            ...item?.valid_till && { valid_till: item?.valid_till.format('YYYY-MM-DD') },
            ...item?.start_date && { start_date: item?.start_date.format('YYYY-MM-DD') },
            ...!item?.newProduct && { policy_id: item?.id },
            ...(!item?.newProduct && item?.tpa) && { tpa_id: item?.tpa_id },
            ...(item?.newProduct && item?.tpa) && { tpa_id: item?.tpa },
            ...(!item?.newProduct && item?.coverage_types) && { coverage_type_list: item?.coverage_types?.map((x: any) => { return x?.id }) },
            ...(item?.newProduct && item?.coverage_type) && { coverage_type_list: [item?.coverage_type] },
            ...(item?.newProduct && item?.intent && !UNDEFINED_ARRAY?.includes(item?.cd_balance)) && { cd_balance: item?.cd_balance },
            ...(!item?.newProduct && item?.intent && !UNDEFINED_ARRAY?.includes(item?.cd_balance) && initialData?.products?.find((x: any) => x?.id === item?.id && UNDEFINED_ARRAY?.includes(x?.cd_balance))) && { cd_balance: item?.cd_balance },
            ...(item?.intent && !UNDEFINED_ARRAY?.includes(item?.commission)) && { commission_percentage: item?.commission },
            ...(item?.intent && !UNDEFINED_ARRAY?.includes(item?.rewards_amount)) && { rewards_amount: item?.rewards_amount },
            ...(item?.intent && item?.issue_booking_date) && { issue_booking_date: item?.issue_booking_date?.format('YYYY-MM-DD HH:mm:ss') },
            ...(item?.intent && item?.inception_payment_frequency && item?.endorsement_payment_frequency) && {
              payment_frequency: item?.payment_frequency?.map((x: any) => {
                if (x?.type === 'INCEPTION') {
                  return {
                    ...x,
                    payment_frequency: item?.inception_payment_frequency
                  }
                }
                return {
                  ...x,
                  payment_frequency: item?.endorsement_payment_frequency
                }
              })
            },
            ...(item?.intent && item?.source) && { source_id: item?.source },
            ...(item?.intent && item?.pricing_matrix) && {
              service_pricing: {
                pricing_type: "PER_LIFE_PRICING_MATRIX",
                formula: {
                  pricing_matrix: item?.pricing_matrix,
                }
              }
            },
            ...(item?.intent && !UNDEFINED_ARRAY?.includes(item?.selling_price)) && {
              service_pricing: {
                pricing_type: "PER_LIFE_SELLING_PRICING",
                formula: {
                  selling_price: item?.selling_price,
                }
              }
            },
            ...(item?.intent && !UNDEFINED_ARRAY?.includes(item?.mille_rate)) && {
              service_pricing: {
                pricing_type: "PER_LIFE_MILLE_RATE_PRICING",
                formula: {
                  mille_rate: item?.mille_rate,
                }
              }
            }
          }
        }
      })?.concat(values?.products?.filter((item: any) => item?.type === 'subscription' && item?.valid)?.map((item: any) => {
        return {
          product_id: item?.newProduct ? item?.id : item?.provider_product_mapping?.product?.id,
          category: item?.newProduct ? item?.category : item?.provider_product_mapping?.product?.category,
          provider_id: item?.newProduct ? item?.provider_name : item?.provider_product_mapping?.provider?.id,
          ...item?.lives_covered && { lives_covered: item?.lives_covered },
          subscription: {
            ...!item?.newProduct && { subscription_id: item?.id },
            ...item?.internal_remarks && { remarks: item?.internal_remarks },
            ...(!item?.newProduct && item?.coverage_types) && { coverage_type_list: item?.coverage_types?.map((x: any) => { return x?.id }) },
            ...(item?.newProduct && item?.coverage_type) && { coverage_type_list: [item?.coverage_type] },
            ...item?.valid_till && { valid_till: item?.valid_till.format('YYYY-MM-DD') },
            ...(item?.newProduct && item?.intent && !UNDEFINED_ARRAY?.includes(item?.cd_balance)) && { cd_balance: item?.cd_balance },
            ...(!item?.newProduct && item?.intent && !UNDEFINED_ARRAY?.includes(item?.cd_balance) && initialData?.products?.find((x: any) => x?.id === item?.id && UNDEFINED_ARRAY?.includes(x?.cd_balance))) && { cd_balance: item?.cd_balance },
            ...(!item?.newProduct && item?.plan) && { plan: item?.plan?.map((x: any) => { return x?.uuid }) },
            ...(item?.newProduct && item?.plan) && { plan: item?.plan?.filter((x: any) => x?.selected)?.map((x: any) => { return x?.uuid }) },
            ...(item?.intent && item?.plan) && {
              service_pricing: {
                pricing_type: "PER_LIFE_SELLING_PRICING",
                formula: {
                  ...!item?.newProduct && { selling_price: item?.plan?.map((x: any) => { return { uuid: x?.uuid, selling_price: x?.selling_price } }) },
                  ...item?.newProduct && { selling_price: item?.plan?.filter((x: any) => x?.selected)?.map((x: any) => { return { uuid: x?.uuid, selling_price: x?.selling_price } }) }
                }
              }
            }
          }
        }
      }))?.concat((values?.products?.filter((item: any)=> item?.type === 'retail' && item?.valid)?.map((item: any) => {
        return {
          product_id: item?.newProduct ? item?.id : item?.provider_product_mapping?.product?.id,
          category: item?.newProduct ? item?.category : item?.provider_product_mapping?.product?.category,
          provider_id: item?.newProduct ? item?.provider_name : item?.provider_product_mapping?.provider?.id,
          ...item?.lives_covered && { lives_covered: item?.lives_covered },
          retail: {
            ...!item?.newProduct && { retail_id: item?.id },
            ...(!item?.newProduct && item?.tpa) && { tpa_id: item?.tpa_id },
            ...(item?.newProduct && item?.tpa) && { tpa_id: item?.tpa },
            ...item?.esc_deductible && { esc_deductible: item?.esc_deductible },
            ...item?.parents_deductible && { parents_deductible: item?.parents_deductible },
            ...item?.internal_remarks && { remarks: item?.internal_remarks },
            ...(!item?.newProduct && item?.coverage_types) && { coverage_type_list: item?.coverage_types?.map((x: any) => { return x?.id }) },
            ...(item?.newProduct && item?.coverage_type) && { coverage_type_list: [item?.coverage_type] },
            ...(item?.newProduct && item?.intent && !UNDEFINED_ARRAY?.includes(item?.cd_balance)) && { cd_balance: item?.cd_balance },
            ...(!item?.newProduct && item?.intent && !UNDEFINED_ARRAY?.includes(item?.cd_balance) && initialData?.products?.find((x: any)=> x?.id === item?.id && UNDEFINED_ARRAY?.includes(x?.cd_balance))) && { cd_balance: item?.cd_balance },
            ...(item?.intent && !UNDEFINED_ARRAY?.includes(item?.commission)) && { commission_percentage: item?.commission },
            ...(item?.intent && !UNDEFINED_ARRAY?.includes(item?.rewards_amount)) && { rewards_amount: item?.rewards_amount },
            ...(item?.intent && item?.issue_booking_date) && { issue_booking_date: item?.issue_booking_date?.format('YYYY-MM-DD HH:mm:ss') },
            ...(item?.intent && item?.inception_payment_frequency && item?.endorsement_payment_frequency) && {
              payment_frequency: item?.payment_frequency?.map((x: any) => {
                if (x?.type === 'INCEPTION') {
                  return {
                    ...x,
                    payment_frequency: item?.inception_payment_frequency
                  }
                }
                return {
                  ...x,
                  payment_frequency: item?.endorsement_payment_frequency
                }
              })
            },
            ...(item?.intent && item?.source) && { source_id: item?.source },
            ...(item?.intent && item?.pricing_matrix) && {
              service_pricing: {
                pricing_type: "PER_LIFE_PRICING_MATRIX",
                formula: {
                  pricing_matrix: item?.pricing_matrix,
                }
              }
            },
            ...(item?.intent && !UNDEFINED_ARRAY?.includes(item?.selling_price)) && {
              service_pricing: {
                pricing_type: "PER_LIFE_SELLING_PRICING",
                formula: {
                  selling_price: item?.selling_price,
                }
              }
            },
            ...(item?.intent && !UNDEFINED_ARRAY?.includes(item?.mille_rate)) && {
              service_pricing: {
                pricing_type: "PER_LIFE_MILLE_RATE_PRICING",
                formula: {
                  mille_rate: item?.mille_rate,
                }
              }
            }
          }
        }
      })))
    },
    reformatInitialData = (policies: any, retails: any, subscriptions: any) => {
      let products = {
        policies: policies?.map((item: any) => {
          return {
            ...item,
            type: 'policy',
            disable: Object.keys(item?.transactions_details)?.length === 0,
            disableIntent: Object.keys(item?.transactions_details)?.length !== 0,
            disablePolicyDetails: true,
            disableBalance: !UNDEFINED_ARRAY?.includes(item?.transactions_details?.cd_balance),
            newProduct: false,
            intent: Object.keys(item?.transactions_details)?.length !== 0,
            validationArray: item?.provider_product_mapping?.product?.additional_fields?.organisation,
            mandatoryArray: [],
            ...item?.provider_product_mapping?.product?.name && { product_type: item?.provider_product_mapping?.product?.name },
            ...item?.provider_product_mapping?.provider?.name && { provider_name: item?.provider_product_mapping?.provider?.name },
            ...item?.tpa_name && { tpa: item?.tpa_name },
            ...item?.tpa_id && { tpa_id: item?.tpa_id },
            ...item?.coverage_types?.[0]?.name && { coverage_type: item?.coverage_types?.[0]?.name },
            ...item?.policy_number && { policy_number: item?.policy_number },
            ...item?.deductible && { deductible: item?.deductible },
            ...item?.remarks && { internal_remarks: item?.remarks },
            ...item?.valid_till && { valid_till: item?.valid_till ? moment(item?.valid_till) : undefined },
            ...item?.start_date && { start_date: item?.start_date ? moment(item?.start_date) : undefined },
            ...!UNDEFINED_ARRAY?.includes(item?.transactions_details?.cd_balance) && { cd_balance: item?.transactions_details?.cd_balance },
            ...!UNDEFINED_ARRAY?.includes(item?.transactions_details?.commission) && { commission: item?.transactions_details?.commission },
            ...item?.transactions_details?.inception_payment_frequency && { inception_payment_frequency: item?.transactions_details?.inception_payment_frequency },
            ...item?.transactions_details?.endorsement_payment_frequency && { endorsement_payment_frequency: item?.transactions_details?.endorsement_payment_frequency },
            ...item?.issue_booking_date && { issue_booking_date: item?.issue_booking_date ? moment(item?.issue_booking_date) : undefined },
            ...item?.transactions_details?.source?.id && { source: item?.transactions_details?.source?.id },
            ...!UNDEFINED_ARRAY?.includes(item?.transactions_details?.pricing_type?.mille_rate) && { mille_rate: item?.transactions_details?.pricing_type?.mille_rate },
            ...!UNDEFINED_ARRAY?.includes(item?.transactions_details?.pricing_type?.selling_price) && { selling_price: item?.transactions_details?.pricing_type?.selling_price },
            ...item?.transactions_details?.pricing_type?.pricing_matrix && { pricing_matrix: item?.transactions_details?.pricing_type?.pricing_matrix },
          }
        }),
        retails: retails?.map((item: any) => {
          return {
            ...item,
            type: 'retail',
            disable: true,
            disableIntent: true,
            disablePolicyDetails: true,
            disableBalance: true,
            newProduct: false,
            intent: true,
            validationArray: item?.provider_product_mapping?.product?.additional_fields?.organisation,
            mandatoryArray: [],
            ...item?.tpa_name && { tpa: item?.tpa_name },
            ...item?.tpa_id && { tpa_id: item?.tpa_id },
            ...item?.provider_product_mapping?.product?.name && { product_type: item?.provider_product_mapping?.product?.name },
            ...item?.provider_product_mapping?.provider?.name && { provider_name: item?.provider_product_mapping?.provider?.name },
          }
        }),
        subscriptions: subscriptions?.map((item: any) => {
          return {
            ...item,
            type: 'subscription',
            disable: Object.keys(item?.transactions_details)?.length === 0,
            disableIntent: Object.keys(item?.transactions_details)?.length !== 0,
            disablePolicyDetails: true,
            disableBalance: !UNDEFINED_ARRAY?.includes(item?.transactions_details?.cd_balance),
            validationArray: item?.provider_product_mapping?.product?.additional_fields?.organisation,
            newProduct: false,
            intent: Object.keys(item?.transactions_details)?.length !== 0,
            mandatoryArray: [],
            ...item?.provider_product_mapping?.product?.name && { product_type: item?.provider_product_mapping?.product?.name },
            ...item?.provider_product_mapping?.provider?.name && { provider_name: item?.provider_product_mapping?.provider?.name },
            ...item?.coverage_types?.[0]?.name && { coverage_type: item?.coverage_types?.[0]?.name },
            ...item?.remarks && { internal_remarks: item?.remarks },
            ...item?.valid_till && { valid_till: item?.valid_till ? moment(item?.valid_till) : undefined },
            ...item?.plan && { plan: item?.plan },
            ...!UNDEFINED_ARRAY?.includes(item?.transactions_details?.cd_balance) ? { cd_balance: item?.transactions_details?.cd_balance } : {cd_balance: undefined},
            ...!UNDEFINED_ARRAY?.includes(item?.transactions_details?.commission) && { commission: item?.transactions_details?.commission },
            ...item?.plan && {
              plan: item?.plan?.map((p: any) => {
                return {
                  ...p,
                  selling_price: item?.transactions_details?.pricing_type?.selling_price?.find((x: any) => x.uuid === p.uuid)?.selling_price
                }
              })
            }
          }
        }),
      }
      return { products: products?.policies?.concat(products?.retails)?.concat(products?.subscriptions) || [] }
    },
    formSubmitHandler = () => {
      form.validateFields()
        .then((values: any) => {
          const reformattedProducts = reformatPayload(values);
          const data = {
            object_id: id,
            purchaser_type: 'organisation',
            subscribed_products: reformattedProducts,
          };
          setBtnloading(true);
          api.clientaddition
            .updateClientProducts(data)
            .then((response) => {
              setBtnloading(false);
              successNotification("Transaction details successfully updated")
              navigateToConfiguration()
            })
            .catch((error) => {
              setBtnloading(false);
              errorHandler(error?.response?.data);
            });
        })
        .catch((err: any) => {
          setBtnloading(false);
        });
    },
    fetchClientDetails = () => {
      api.clientaddition
        .getClientDetails(id)
        .then((response) => {
          setClientDetails(response?.data);
        })
        .catch((error) => {
          errorHandler(error?.response?.data);
        });
    },
    routes = [
      {
        path: '/client-manager/corporate/:id',
        breadcrumb: ActiveBreadcrump,
        props: { value: clientDetails?.brand_name },
      },
    ],
    updateButtonState = () => {
      setBtnDisabled(
        (form.getFieldValue('products')?.find((policy: any) => !policy.valid))
          ? true : false
      )
    },
    getData = () => {
      setLoading(true);
      api.member_management
        .getSale({ organisation_id: id })
        .then((res) => {
          if (res?.data?.count === 0) {
            setData({ products: [] })
            setInitialData({ products: [] })
          } else {
            let reformattedProducts = reformatInitialData(res?.data?.results?.[0].policies || [], res?.data?.results?.[0]?.retails || [], res?.data?.results?.[0]?.subscriptions || [])
            setData(reformattedProducts)
            setInitialData(reformattedProducts)
          }
        })
        .catch((err) => {
          errorHandler(err?.response?.data);
        });
    },
    addProduct = () => {
      let newBlankProduct = listOfProducts?.find((item: any) => item?.id === newProduct)
      let policyArray = form.getFieldValue('products')
      policyArray[policyArray.length] = {
        ...newBlankProduct,
        disable: true,
        disableIntent: newBlankProduct?.category === 'retail_insurance' ? true : false,
        disablePolicyDetails: false,
        disableBalance: false,
        newProduct: true,
        intent: newBlankProduct?.category === 'retail_insurance' ? true : false,
        product_type: newBlankProduct?.name,
        validationArray: newBlankProduct?.additional_fields?.organisation,
        mandatoryArray: newBlankProduct?.required_fields,
        type: newBlankProduct?.category === 'insurance' ? 'policy' : newBlankProduct?.category === 'retail_insurance' ? 'retail' : 'subscription'
      }
      form.setFieldsValue({ products: policyArray })
      setData({ products: policyArray })
      setNewProduct(undefined)
    },
    removeProduct = (id: number) => {
      let policyArray = form.getFieldValue('products')?.filter((item: any) => item?.id !== id)
      form.setFieldsValue({
        products: policyArray?.filter((item: any) => item?.id !== id)
      })
      form.setFieldsValue({ products: policyArray })
      setData({ products: policyArray })
    };

  useEffect(() => {
    fetchClientDetails();
    if (id) {
      getData()
    }
  }, [])

  useEffect(() => {
    if (data) {
    setLoading(false)
      if (data?.products?.length > 0) {
        updateButtonState()
      } else {
        setBtnDisabled(true)
      }
    }
  }, [data])

  return <Loader
    loading={loading}
  >
    <Breadcrumbs routes={routes} />
    <NoteModal
      isModalVisible={helpModalVisible}
      cancelHandler={() => setHelpModalVisible(false)}
      modalTitle="Instructions"
      type='note'
      data={{
        notes: `
          For products like Group Health Insurance, we have pricing matrix to make it easy to handle multiple variable like age bands, sum insured and the premium. To add a pricing matrix follow these steps.<br><br>

          <b>Get the Template:</b> There should be a link next to pricing matrix upload section that says "Download Template". Click that link and save the file to your computer.<br><br>

          <b>Edit the Template:</b>  In the header, first two cells, i.e A1 and B1 should stay untouched. From cell C1, you can enter all the sum insured amounts that the company has opted for. These should be numerical values only.<br><br>

          <b>Fill in Age Bands:</b>The age band at the top should be for the youngest people you cover, and the one at the bottom for the oldest. Make sure to arrange them in order from lowest to highest. Each age band must start at a higher age than where the previous one ended.<br><br>

          <b>Fill in Sum Insured:</b>  Corresponding to the age band and the sum insured, enter the premium value. Make sure to only put in numbers only (avoid using commas, alphabets and special characters).<br><br>

          <b>Fill in Premiums:</b> After that, fill in the premiums. The premium is how much each person has to pay for their insurance. Again, only put in numbers only (avoid using commas, alphabets and special characters). Make sure to fill in a value for each age band and sum insured. Don't leave any cells empty.<br><br>

          <b>Upload the File:</b> Once you've filled in all your information, save the file and go back to the webpage. Click the "Click to Upload" button and select your saved file.<br><br>

          <b>Check the Preview: </b>After you upload the file, the website will show you a preview. This is your chance to double-check everything. If something doesn't look right, you can go back, change the information in your file, save it again, and re-upload it.
        `,
      }}
    />
    <Form
      form={form}
      initialValues={data}
      className="form-bottom-border"
      style={{ margin: '48px 0' }}
    >
      <Alert
        showIcon
        className="mb-24"
        type="info"
        message={
          <span>
            Please make sure that all the transaction details have been correctly added for a product.
            <br />
            Once saved, transaction details cannot be edited. Partially added transaction details will not be saved for a product.
          </span>
        }
      />
      <Form.List name="products">
        {(fields) => (
          <React.Fragment>
            {fields.map(({ key, name, ...restField }) => (
              <AddPolicyTransactions
                name={name}
                restField={restField}
                initialData={data?.products}
                form={form}
                setHelpModalVisible={setHelpModalVisible}
                transaction_only={transaction_only || false}
                remove={removeProduct}
                updateButtonState={updateButtonState}
              />
            ))}
            {!transaction_only && <React.Fragment>
              <div style={{
                font: 'normal 600 16px/22px "Inter"',
                marginBottom: '4px'
              }}>Add another product</div>
              <Select className="mr-24" onChange={(val) => { setNewProduct(val) }} value={newProduct} style={{ width: '300px' }} placeholder="Select a product">
                {listOfProducts?.map((item: any, index: any) => {
                  return <Select.Option key={index} value={item?.id}
                    disabled={form.getFieldValue('products')?.find((x: any) => x?.provider_product_mapping?.product?.id === item?.id)}>
                    {item.name}
                  </Select.Option>
                })}
              </Select>
              <Button disabled={newProduct ? false : true} type="primary" size="small" onClick={addProduct}>Add Product</Button>
            </React.Fragment>}
          </React.Fragment>
        )}
      </Form.List>
    </Form>
    <Row gutter={16} justify="end">
      <Col>
        <Button type="secondary" onClick={navigateToConfiguration}>Back</Button>
      </Col>
      <Col>
        <Button
          disabled={btnDisabled}
          type="primary"
          onClick={formSubmitHandler}
          loading={btnloading}
        >
          Submit
        </Button>
      </Col>
    </Row>
  </Loader>
}

export default AddTransactionDetails;
