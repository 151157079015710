import { Outlet, useLocation, useParams } from "react-router-dom"
import './_claimsmanager.scss';
import axios from "axios";
import api from "../../../api/api";
import { useEffect, useLayoutEffect, useState } from "react";
import { errorHandler, onInputChangeHandler } from "../../../utils/helper";
import Loader from "../../../components/Loader/Loader";
import { successNotification } from "../../../components/UI/Toast/Toast";
import { useSelector } from "react-redux";
import { ActiveBreadcrump } from "../../../components/header/Breadcrumbs";
import { Col, Row } from "antd";

export type outletContext = {
  claim: any,
  getClaim: () => void,
  updateClaim: (id: any, data: any, successMessage: string) => void,
  changeHandler: (key: Array<string>) => void,
  errorMessage: any,
  setErrorMessage: (error: any) => void,
  choices: any,
  routes: any,
  isUnmappedField: (key: string, nestedKeys?:Array<string>) => string
}

type location = {
  state: {
    data?: any;
  }
}

const Claim: React.FC = () => {
  const { id, claimid } = useParams(),
    location = useLocation() as location,
    [claim, setClaim] = useState<undefined | any>(),
    [errorMessage, setErrorMessage] = useState<null | any>(null),
    [loading, setLoading] = useState(true),
    choices = useSelector((state: any) => state?.masterdata?.choices),
    source = axios.CancelToken.source(),
    changeHandler = (key: Array<string>) => {
      onInputChangeHandler(key, setErrorMessage, errorMessage)
    },
    routes = [
      {
        path: '/claims-manager/view-claim',
        props: { killBreadcrumb: true },
      },
      {
        path: '/claims-manager/view-claim/:claimid',
        props: { value: claim?.claim_id },
      },
      {
        path: 'client-manager/corporate/:id',
        props: { value: claim?.organisation?.organisation_name },
      },
      {
        path: 'client-manager/corporate/:id/claims-manager/view-claim',
        props: { killBreadcrumb: true },
      },
      {
        path: 'client-manager/corporate/:id/claims-manager/view-claim/:claimid',
        props: { value: claim?.claim_id },
        ActiveBreadcrump,
      },
    ],
    getClaim = () => {
      api.claims_management
        .getClaimsList({
          claims_uuid: claimid,
        }, source?.token)
        .then((res: any) => {
          setClaimObject(res?.data?.results?.[0]);
        })
        .catch((err: any) => {
          errorHandler(err?.response?.data)
        });
    },
    updateClaim = (id: any, data: any, successMessage: string) => {
      api.claims_management
        .updateClaim(id, data)
        .then((res) => {
          setClaimObject(res?.data)
          successNotification(successMessage)
        })
        .catch((error) => {
          setErrorMessage(error?.response?.data?.error);
          errorHandler(error?.response?.data);
        });
    },
    setClaimObject = (val: any) => {
      setClaim({
        ...val,
        employee_id: val?.employee_id || val?.additional_details?.response?.employee_id,
        full_name: val?.full_name || val?.additional_details?.response?.member_name,
        raised_for: {
          ...val?.raised_for && {
            ...val?.raised_for,
            name: val?.raised_for?.name || val?.additional_details?.response?.raised_for,
            date_of_birth: val?.raised_for?.date_of_birth || val?.additional_details?.response?.date_of_birth
          },
          ...!val?.raised_for && {
            ...val?.additional_details?.response?.raised_for && {
              name: val?.additional_details?.response?.raised_for,
            },
            ...val?.additional_details?.response?.date_of_birth && {
              date_of_birth: val?.additional_details?.response?.date_of_birth
            }
          },
        }
      });
    },
    isUnmappedField = (key: string, nestedKeys: Array<string>) => {
      if (((!nestedKeys && !claim?.[key]) || (nestedKeys && !claim?.[nestedKeys?.[0]]?.[nestedKeys?.[1]])) && claim?.tpa?.additional_details?.unmapped_fields?.includes(key) && claim?.sync_status) {
        return 'unmapped-field'
      }
      return ''
    }

  useEffect(() => {
    if (claim && choices) {
      setLoading(false);
    }
  }, [claim, choices])

  useEffect(() => {
    if (!location?.state?.data) {
      getClaim();
    }
  }, []);

  useEffect(() => {
    if (location?.state?.data) {
      setClaimObject(location?.state?.data)
    }
  }, [location?.state?.data])

  return <Loader loading={loading}>
    <Outlet context={{
      claim: claim,
      getClaim: getClaim,
      updateClaim: updateClaim,
      changeHandler: changeHandler,
      errorMessage: errorMessage,
      setErrorMessage: setErrorMessage,
      choices: choices,
      routes: routes,
      isUnmappedField: isUnmappedField
    }} />
  </Loader>
}

export default Claim;
